import Alpine from 'alpinejs'
//import categorias from './categorias';
//import subcategorias from './subcategorias';

import cep from './cep';

window.Alpine = Alpine
//categorias();
//subcategorias();

cep();
Alpine.start();